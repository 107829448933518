<template>
  <main>
    <ThinqLnb />
    <router-view />
  </main>
</template>

<script>
import ThinqLnb from "./ThinqLnb.vue";

export default {
  components: { ThinqLnb },
};
</script>
